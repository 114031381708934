import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
// ColorMode,
import {  NetworkType } from "@airgap/beacon-sdk";
import { CONTRACT_ADDRESS } from "@/utils";
import BigNumber from "bignumber.js";

// Set the network
// const network = { type: NetworkType.ITHACANET };

const network = { type: NetworkType.GHOSTNET };
// const network = { type: NetworkType.MAINNET };

// initialiize the SDK
// const Tezos = new TezosToolkit("https://ithacanet.ecadinfra.com");

// NEW RPC NODE PROVIDER
// GHOSTNET - wieder testenets.xyz ändern?
// const Tezos = new TezosToolkit("https://ghostnet.tezos.marigold.dev");
const Tezos = new TezosToolkit("https://rpc.ghostnet.teztnets.xyz");


// MAINNET 
// const Tezos = new TezosToolkit("https://mainnet.tezos.marigold.dev/");

const wallet = new BeaconWallet({
  name: "DIYFRAME",
  iconUrl: 'https://contract.diyframe.xyz/favicon.svg',
  preferredNetwork: network.type,
});

Tezos.setWalletProvider(wallet);
const getContract = async () => await Tezos.wallet.at(CONTRACT_ADDRESS);

export default {
  /**
   * @description requests permission to connect to the network
   */
  async connectWallet({ dispatch }) {
    try {
      await wallet.requestPermissions({
        network: network,
      });
      dispatch("checkWalletConnection");
    } catch (error) {
      console.log(error);
    }
  },
// FÜR /FRAME ,tezosWalletAddress
  async connectionToContract({ commit },tezosWalletAddress) {
    console.log("🤝 action.js / connectionToContract()");
   let pkh = tezosWalletAddress; //"tz1i4vowP9mHi83KTJikHwB433id3CXGasqU"
     // let pkh = "tz1i4vowP9mHi83KTJikHwB433id3CXGasqU";

    commit("updatePkh", pkh);
    commit("updateConnected", true);
  },
// ??
  async getFrameListFrame({ state, commit }, frameID) {
    commit("updateLoading", true);
    
    try {
      let active_frame = [];
      let done_frame = [];

      if (state.connected) {
  
        const contract = await getContract();
        const storage = await contract.storage();
        const storage_user_frames = await storage.users.get(state.pkh);

        const user_frames = storage_user_frames.map((val) => new BigNumber(val).toNumber());
        // 
        // TEMP OFF console.log("user_frames ", user_frames);
        // TEMP OFF console.log("state.pkh ",state.pkh);
        for (let index = 0; index < user_frames.length; index++) {
          const frame = await storage.frames.get({
            owner: state.pkh,
            id: frameID // user_frames[index].toString(),
          });
          // 
          // TEMP OFF console.log(frame)

          // const frame = "0x0000f6084181b12f9210e887d8338e3c647e582be89e"

          const formated_frame = { ...frame, id: new BigNumber(frame.id).toString() };

          if (!frame.done) {
            active_frame.push(formated_frame);
          } else {
            done_frame.push(formated_frame);
          }
        }
      }

      commit("updateActiveFrame", active_frame);
      commit("updateDoneFrame", done_frame);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        commit("updateLoading", false);
      }, 1000);
    } // end try catch
  },

  
  /**
   * @description Check if the wallet is connected, updates the pkh in state if connected
   */
  async checkWalletConnection({ commit }) {
    try {
      const activeAccount = await wallet.client.getActiveAccount();
      let pkh;

      if (activeAccount) {
        // If defined, the user is connected to a wallet.
        pkh = activeAccount.address;
        commit("updatePkh", pkh);
        commit("updateConnected", true);
      } else {
        commit("updatePkh", "");
        commit("updateConnected", false);
      }
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * @description disconnects the wallet connection to the dApp
   */
  async disconnectWallet({ dispatch }) {
    await wallet.clearActiveAccount();
    dispatch("checkWalletConnection");

    // set the pkh to empty
  },

  /**
   * @description Gets a list of a users frames from the contract and updates the state TODO check if pkg addres
   */
  async getFrameList({ state, commit }) {
    console.log("🐕 getFrameList()");
    commit("updateLoading", true);

    try {
      let active_frame = [];
      let done_frame = [];


      if (state.connected) {
  
        const contract = await getContract();
        const storage = await contract.storage();
        const storage_user_frames = await storage.users.get(state.pkh);

        console.log("storage_user_frames ",storage_user_frames);
        // TODO if any frames created yet
        if(storage_user_frames === undefined) {
        
return
        }

        const user_frames = storage_user_frames.map((val) => new BigNumber(val).toNumber());
        console.log("// _user_frames", user_frames);

        for (let index = 0; index < user_frames.length; index++) {
          const frame = await storage.frames.get({
            owner: state.pkh,
            id: user_frames[index].toString(),
          });

          const formated_frame = { ...frame, id: new BigNumber(frame.id).toString() };

          if (!frame.done) {
            active_frame.push(formated_frame);
          } else {
            done_frame.push(formated_frame);
          }
        }
      }

      console.log("TODO: convertFrameIDS hier",);
      
      commit("updateActiveFrame", active_frame);
      commit("updateDoneFrame", done_frame);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        commit("updateLoading", false);
      }, 1000);
    } // end try catch
  },
  /**
   * @description Gets a list of a users frames from the contract and updates the state without loading the page
   */
  async softUpdateFrameList({ state, commit }) {
    try {
      let active_frame = [];
      let done_frame = [];

      if (state.connected) {
  
        const contract = await getContract();
        const storage = await contract.storage();
        const storage_user_frames = await storage.users.get(state.pkh);

        const user_frames = storage_user_frames.map((val) => new BigNumber(val).toNumber());
        console.log("_softUpdateFrameList / _user_frames", user_frames);

        for (let index = 0; index < user_frames.length; index++) {
          const frame = await storage.frames.get({
            owner: state.pkh,
            id: user_frames[index].toString(),
          });

          const formated_frame = { ...frame, id: new BigNumber(frame.id).toString() };

          if (!frame.done) {
            active_frame.push(formated_frame);
          } else {
            done_frame.push(formated_frame);
          }
        }
      }

      commit("updateActiveFrame", active_frame);
      commit("updateDoneFrame", done_frame);
    } catch (error) {
      console.log(error);
    } 
  },
  /**
   * @param {string} frame
   * @description Creates a new frame and adds it to the contract storage
   */
  async addFrame({ dispatch }, frame) {
    console.log("frame ",frame);
    try {
      const contract = await getContract();
      const op = await contract.methods.addFrame(frame).send();
      await op.confirmation()
      dispatch("softUpdateFrameList");
    } catch (error) {
      console.log(error);
    }
  },

    /**
   * @param {string} id
   * @description deletes the frame with the given id from the contract storage
   */
  async deleteFrame({ dispatch }, id) {
    try {
      const contract = await getContract();
      const op = await contract.methods.deleteFrame(id).send();
      await op.confirmation()
      dispatch("softUpdateFrameList");
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * @param {string} id
   * @description toggles the done field for the given id from the contract storage
   */
  async toggleFrameStatus({ dispatch }, id) {
    try {
      const contract = await getContract();
      const op = await contract.methods.toggleFrameStatus(id).send();
      await op.confirmation()
      dispatch("softUpdateFrameList");
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * @param {string} id
   * @description updates the frame with the given id from the contract storage
   */
  async updateFrame({ dispatch }, payload) {
    try {
      const contract = await getContract();
      const op = await contract.methodsObject.updateFrame(payload).send();
      await op.confirmation()
      dispatch("softUpdateFrameList");
      console.log("action.js / dispatch softUpdateFrameList");
    } catch (error) {
      console.log(error);
    }
  },
};
